import styled from 'styled-components'

type StyledALinkProps = {
  $hoverColor: 'blue' | 'gray1' | 'gray2' | 'gray3' | 'gray4' | 'white'
  $height?: string
}

export const StyledALink = styled.a<StyledALinkProps>`
  color: inherit;
  text-decoration: none;
  width: fit-content;
  height: ${({ $height }) => $height};
  position: relative;
  padding: 0.2em 0;
  overflow: hidden;

  --color: ${({ theme, $hoverColor }) => theme.colors[$hoverColor]};

  &:hover {
    color: var(--color);
  }
  &:active {
    color: var(--color);
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: var(--color);
    transition:
      opacity 300ms,
      transform 300ms;
    opacity: 1;
    transform: translate3d(-100%, 0, 0);
  }

  &:hover::after,
  &:focus::after {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`
