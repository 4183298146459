import React from 'react'

type SvgInstaProps = {
  className?: string
  color?: string
}

const SvgInsta = ({ className, color = '#42474A' }: SvgInstaProps) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M7.5 4.40625C5.5 4.40625 3.90625 6.03125 3.90625 8C3.90625 10 5.5 11.5938 7.5 11.5938C9.46875 11.5938 11.0938 10 11.0938 8C11.0938 6.03125 9.46875 4.40625 7.5 4.40625ZM7.5 10.3438C6.21875 10.3438 5.15625 9.3125 5.15625 8C5.15625 6.71875 6.1875 5.6875 7.5 5.6875C8.78125 5.6875 9.8125 6.71875 9.8125 8C9.8125 9.3125 8.78125 10.3438 7.5 10.3438ZM12.0625 4.28125C12.0625 3.8125 11.6875 3.4375 11.2188 3.4375C10.75 3.4375 10.375 3.8125 10.375 4.28125C10.375 4.75 10.75 5.125 11.2188 5.125C11.6875 5.125 12.0625 4.75 12.0625 4.28125ZM14.4375 5.125C14.375 4 14.125 3 13.3125 2.1875C12.5 1.375 11.5 1.125 10.375 1.0625C9.21875 1 5.75 1 4.59375 1.0625C3.46875 1.125 2.5 1.375 1.65625 2.1875C0.84375 3 0.59375 4 0.53125 5.125C0.46875 6.28125 0.46875 9.75 0.53125 10.9062C0.59375 12.0312 0.84375 13 1.65625 13.8438C2.5 14.6562 3.46875 14.9062 4.59375 14.9688C5.75 15.0312 9.21875 15.0312 10.375 14.9688C11.5 14.9062 12.5 14.6562 13.3125 13.8438C14.125 13 14.375 12.0312 14.4375 10.9062C14.5 9.75 14.5 6.28125 14.4375 5.125ZM12.9375 12.125C12.7188 12.75 12.2188 13.2188 11.625 13.4688C10.6875 13.8438 8.5 13.75 7.5 13.75C6.46875 13.75 4.28125 13.8438 3.375 13.4688C2.75 13.2188 2.28125 12.75 2.03125 12.125C1.65625 11.2188 1.75 9.03125 1.75 8C1.75 7 1.65625 4.8125 2.03125 3.875C2.28125 3.28125 2.75 2.8125 3.375 2.5625C4.28125 2.1875 6.46875 2.28125 7.5 2.28125C8.5 2.28125 10.6875 2.1875 11.625 2.5625C12.2188 2.78125 12.6875 3.28125 12.9375 3.875C13.3125 4.8125 13.2188 7 13.2188 8C13.2188 9.03125 13.3125 11.2188 12.9375 12.125Z"
        fill={color}
      />
    </svg>
  )
}

export default SvgInsta
