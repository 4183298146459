export type IBreakpointKeys = Record<keyof typeof breakpoints, string>

export const breakpoints = {
  largeMobile: '377px',
  tablet: '768px',
  largeTablet: '855px',
  desktop: '1024px',
  widescreen: '1440px',
  uhd: '1920px',
}
